import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-add-point',
  templateUrl: './dialog-add-point.component.html',
  styleUrls: ['./dialog-add-point.component.css']
})
export class DialogAddPointComponent {
  point:  number = null;
  number: number = null;
  constructor(
    public dialogRef: MatDialogRef<DialogAddPointComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  add(): void {
    this.dialogRef.close({number: this.number, point: this.point});
  }
}
