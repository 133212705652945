import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Player } from '../model/player';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-list-person',
  templateUrl: './list-person.component.html',
  styleUrls: ['./list-person.component.css']
})
export class ListPersonComponent implements OnInit {
  players: Player[];
  name: string;
  @Output() emit: EventEmitter<Player[]> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.name = null;
    this.players = [];
  }

  clearPlayer(index: number): void {
    this.players.splice(index, 1);
  }

  addPlayer(name: string): void {
    if ( name && name !== '' ) {
      const newPlayer = new Player();
      newPlayer.name = name;
      this.players.push(newPlayer);
      this.name = '';
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.players, event.previousIndex, event.currentIndex);
  }

  emitPlayers(): void {
    this.emit.emit(this.players);
  }

}
