import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GamesComponent } from './games/games.component';
import { CricketComponent } from './all-games/cricket/cricket.component';
import { CrazyCricketComponent } from './all-games/crazy-cricket/crazy-cricket.component';
import { NumberMaxComponent } from './all-games/number-max/number-max.component';

const routes: Routes = [{
  path: '',
  redirectTo : 'home',
  pathMatch: 'full'
},
{
  path: 'home',
  component : HomeComponent
},
{
  path: 'games',
  component : GamesComponent
}, {
  path: 'cricket',
  component: CricketComponent
}, {
  path: 'crazy-cricket',
  component: CrazyCricketComponent
}, {
  path: 'number/:max',
  component: NumberMaxComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
