import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'darts';

  constructor (
    meta: Meta
  ) {
      meta.addTags([
        { name: 'author', content: 'Mathieu Douale' },
        { name: 'description', content: 'This is application for score for darts' },
        { name: 'keywords', content: 'Darts, Fléchettes, Score, Cibles, Cricket, Target, Dartboard'}
      ]);
    }
}
