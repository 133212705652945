import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit {
  sizeHeight: number;
  sizeWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sizeHeight =  event.target.innerHeight;
    this.sizeWidth =  event.target.innerWidth;
  }
  constructor() { }

  ngOnInit() {
    this.sizeWidth = window.innerWidth;
    this.sizeHeight = window.innerHeight;
  }

}
