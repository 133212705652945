export class Player {
    id: Number = 0;
    name: String = '';

    constructor(player?: Player) {
        if ( player ) {
            this.id = player.id;
            this.name = player.name;
        }
    }
}
