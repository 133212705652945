import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.css']
})
export class TargetComponent implements OnInit {
  @Output() emitPoint: EventEmitter<number[]> = new EventEmitter();
  sizeHeight: number;
  sizeWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sizeHeight =  event.target.innerHeight;
    this.sizeWidth =  event.target.innerWidth;
  }
  constructor() { }

  ngOnInit() {
    this.sizeWidth = window.innerWidth;
    this.sizeHeight = window.innerHeight;
  }

  addPoint(number: number, point: number): void {
    this.emitPoint.emit([number, point]);
  }
}
