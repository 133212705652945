import { Component, OnInit, Input } from '@angular/core';
import { Player } from 'src/app/model/player';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddPointComponent } from 'src/app/dialog-add-point/dialog-add-point.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-number-max',
  templateUrl: './number-max.component.html',
  styleUrls: ['./number-max.component.css']
})
export class NumberMaxComponent implements OnInit {
  cache: any[];
  name = '';
  run = false;
  round: number;
  play: Player;
  test: any = new Array();
  dartOne: any;
  dartSecond: any;
  dartThird: any;
  playerId: number;
  score: any;
  scoreTotal: any;
  players: Player[];
  closed: boolean;
  max: string;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  newGame(): void {
    this.players = [];
    this.playerId = 0;
    this.score = [];
  }

  close(): void {
    this.closed = true;
  }

  open(): void {
    this.closed = false;
  }

  setPlayer($event): void {
    this.players = $event;
    this.players.map((player, index) => player.id = index);
    this.run = true;
    this.round = 1;
    this.play = this.players[0];
    this.playerId = 0;
    this.score = [];
    this.scoreTotal = [];
    this.players.forEach((element, index) => {
      this.scoreTotal[index] = 0;
      this.score[index] = [];
      this.score[index][0] = 0;
    });
  }


  ngOnInit(): void {
    this.max = this.route.snapshot.paramMap.get('max');
    this.players = [];
    this.playerId = 0;
    this.round = 0;
    this.play = new Player();
    this.dartOne = { number : null , point : null};
    this.dartSecond = { number : null , point : null};
    this.dartThird = { number : null , point : null};
  }

  precedentRound(): void {
    if ( this.dartOne.number === null ) {
      if ( this.playerId > 0 ) {
        this.playerId--;
        this.play = this.players[this.playerId];
      } else {
        this.playerId = this.players.length - 1;
        this.play = this.players[this.playerId];
        this.round--;
      }
      this.scoreTotal[this.playerId] -= this.score[this.playerId][this.round - 1];
      this.score[this.playerId][this.round - 1] = 0;
      this.dartOne = { number : null , point : null};
      this.dartSecond = { number : null , point : null};
      this.dartThird = { number : null , point : null};
    }
  }

  nextRound(): void {
    if (!this.scoreTotal[this.playerId] || this.scoreTotal[this.playerId] === null ) {
      this.scoreTotal[this.playerId] = 0;
    }
    if (!this.score[this.playerId][this.round - 1] || this.score[this.playerId][this.round - 1] === null) {
      this.score[this.playerId][this.round - 1] = 0;
    }
    this.scoreTotal[this.playerId] += this.score[this.playerId][this.round - 1];

    this.dartOne = { number : null , point : null};
    this.dartSecond = { number : null , point : null};
    this.dartThird = { number : null , point : null};
    this.playerId++;
    this.play = this.players[this.playerId];
    if ( this.playerId + 1 > this.players.length) {
      this.playerId = 0;
      this.play = this.players[this.playerId];
      this.round++;
    }
  }

  targetPoint(event: number[]): void {
    this.addPoint(event[0], event[1]);
  }

  addPoint(number: number, point: number): void {
    if (!point) {
      point = 0;
    }
    if (!number) {
      number = 0;
    }
    if ( this.dartOne.number === null ) {
      this.dartOne = { number : number , point : point};
    } else if ( this.dartSecond.number === null) {
      this.dartSecond = { number : number , point : point};
    } else if ( this.dartThird.number === null) {
      this.dartThird = { number : number , point : point};
    } else {
      return;
    }

    if (!this.score[this.playerId][this.round - 1]) {
      this.score[this.playerId][this.round - 1] = 0;
    }

    this.score[this.playerId][this.round - 1] += point * number;
    this.score[this.playerId][this.round] = 0;
    if ( this.scoreTotal[this.playerId] + this.score[this.playerId][this.round - 1] === this.max) {
      this.score[this.playerId][this.round - 1] = 0;
      this.nextRound();
    } else if ( this.scoreTotal[this.playerId] + this.score[this.playerId][this.round - 1] === this.max && number === 2) {
      console.log('fini');
    } else if ( this.scoreTotal[this.playerId] + this.score[this.playerId][this.round - 1] === this.max && number !== 2) {
      this.score[this.playerId][this.round - 1] = 0;
      this.nextRound();
    } else if ( this.scoreTotal[this.playerId] + this.score[this.playerId][this.round - 1] > this.max) {
      this.score[this.playerId][this.round - 1] = 0;
      this.nextRound();
    }
  }

  clear(type: string): void {
    if ( type === 'dartOne' ) {
      this.score[this.playerId][this.round - 1] -= this.dartOne.number * this.dartOne.point;
      this.dartOne = { number : null , point : null};
    } else if ( type === 'dartSecond' ) {
      this.score[this.playerId][this.round - 1] -= this.dartSecond.number * this.dartSecond.point;
      this.dartSecond = { number : null , point : null};
    } else if ( type === 'dartThird' ) {
      this.score[this.playerId][this.round - 1] -= this.dartThird.number * this.dartThird.point;
      this.dartThird = { number : null , point : null};
    }
  }

  newArray(number: number): Array<number> {
    return new Array(number);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogAddPointComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe( result => {
      if ( result && result.point !== null && result.number !== null)  {
        this.addPoint(result.number, result.point);
      }
    });
  }

}
