import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { AppRoutingModule } from './app-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GamesComponent } from './games/games.component';
import { AllGamesComponent } from './all-games/all-games.component';
import { CricketComponent } from './all-games/cricket/cricket.component';
import { ListPersonComponent } from './list-person/list-person.component';
import { TargetComponent } from './target/target.component';
import { CrazyCricketComponent } from './all-games/crazy-cricket/crazy-cricket.component';
import { DialogAddPointComponent } from './dialog-add-point/dialog-add-point.component';
import { NumberMaxComponent } from './all-games/number-max/number-max.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GamesComponent,
    AllGamesComponent,
    CricketComponent,
    ListPersonComponent,
    TargetComponent,
    CrazyCricketComponent,
    DialogAddPointComponent,
    NumberMaxComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CdkTableModule,
    FormsModule,
    MatGridListModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogAddPointComponent
  ],
})
export class AppModule { }
