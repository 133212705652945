import { Component, OnInit } from '@angular/core';
import { Player } from 'src/app/model/player';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddPointComponent } from 'src/app/dialog-add-point/dialog-add-point.component';

@Component({
  selector: 'app-crazy-cricket',
  templateUrl: './crazy-cricket.component.html',
  styleUrls: ['./crazy-cricket.component.css']
})
export class CrazyCricketComponent implements OnInit {
  cache: any[];
  checked: boolean;
  name = '';
  points = [15, 16, 17, 18, 19, 20, 25];
  run = false;
  round: number;
  play: Player;
  test: any = new Array();
  dartOne: any;
  dartSecond: any;
  dartThird: any;
  playerId: number;
  score: any;
  scoreTotal: any;
  players: Player[];

  closed: boolean;

  constructor(
    public dialog: MatDialog
  ) { }

  close(): void {
    this.closed = true;
  }

  open(): void {
    this.closed = false;
  }

  newGame(): void {
    this.players = [];
    this.checked = false;
    this.playerId = 0;
    this.score = [];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogAddPointComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe( result => {
      if ( result && result.point !== null && result.number !== null)  {
        this.addPoint(result.number, result.point);
      }
    });
  }

  setPlayer($event): void {
    this.players = $event;
    this.players.map((player, index) => player.id = index);
    this.run = true;
    this.round = 1;
    this.play = this.players[0];
    this.playerId = 0;
    this.score = [];
    this.scoreTotal = [];
    this.randomPoints();
    this.players.forEach((element, index) => {
      this.scoreTotal[index] = 0;
      this.score[index] = [];
      this.points.forEach(pt => {
        this.score[index][pt] = 0;
      });
    });
  }

  ngOnInit(): void {
    this.checked = false;
    this.cache = [];
    this.players = [];
    this.playerId = 0;
    this.round = 0;
    this.play = new Player();
    this.dartOne = { number : null , point : null};
    this.dartSecond = { number : null , point : null};
    this.dartThird = { number : null , point : null};
  }

  getRandomInt(max): number {
    return (Math.floor(Math.random() * Math.floor(max) + 1));
  }

  randomPoints(): void {
    this.points = [];
    if ( this.checked ) {
      while ( this.points.length < 6) {
        const random = this.getRandomInt(20);
        if ( this.points.indexOf(random) === -1 ) {
          this.points.push(random);
        }
      }
      this.points = this.points.sort(this.sortNumber);
      this.points.push(25);
    } else {
      while ( this.points.length < 7) {
        const random = this.getRandomInt(20);
        if ( this.points.indexOf(random) === -1 ) {
          this.points.push(random);
        }
      }
      this.points = this.points.sort(this.sortNumber);
    }
  }

  sortNumber(a, b) {
    return a - b;
  }

  targetPoint(event: number[]): void {
    this.addPoint(event[0], event[1]);
  }

  precedentRound(): void {
    if ( this.dartOne.number === null ) {
      if ( this.playerId > 0 ) {
        this.playerId--;
        this.play = this.players[this.playerId];
      } else {
        this.playerId = this.players.length - 1;
        this.play = this.players[this.playerId];
        this.round--;
      }
      this.cache.splice(- 1, 1);
      if ( this.cache.length === 0 ) {
        this.players.map((player, index) => player.id = index);
        this.run = true;
        this.round = 1;
        this.play = this.players[0];
        this.playerId = 0;
        this.score = [];
        this.scoreTotal = [];
        this.players.forEach((element, index) => {
          this.scoreTotal[index] = 0;
          this.score[index] = [];
          this.points.forEach(pt => {
            this.score[index][pt] = 0;
          });
        });
      } else {
        this.score = [];
        this.scoreTotal = [];
        this.score = this.cache[this.cache.length - 1].score;
        this.scoreTotal = this.cache[this.cache.length - 1].total;
      }
    }
  }

  nextRound(): void {
    this.addPointOtherPlayer();


    this.dartOne = { number : null , point : null};
    this.dartSecond = { number : null , point : null};
    this.dartThird = { number : null , point : null};
    this.playerId++;
    this.play = this.players[this.playerId];
    if ( this.playerId + 1 > this.players.length) {
      this.playerId = 0;
      this.round++;
      this.play = this.players[0];
      if ( this.round > 20 ) {
        // this.endCriket();
      }
    }
  }

  addPointOtherPlayer(): void {
    this.points.forEach(point => {
      if ( this.score[this.playerId][point] > 3 ) {
        this.addPointScore(this.score[this.playerId][point] - 3, point);
        this.score[this.playerId][point] = 3;
      }
    });
    this.saveLocalStorage();
  }

  addPoint(number: number, point: number): void {
    if ( this.dartOne.number === null ) {
      this.dartOne = { number : number , point : point};
    } else if ( this.dartSecond.number === null) {
      this.dartSecond = { number : number , point : point};
    } else if ( this.dartThird.number === null) {
      this.dartThird = { number : number , point : point};
    } else {
      return;
    }

    this.score[this.playerId][point] = this.score[this.playerId][point] + number;

  }

  addPointScore(number: number, point: number): void {
    this.score.forEach((player, index) => {
      if (player[point]  < 3 ) {
        this.scoreTotal[index] += (number * point);
      }
    });
  }

  clear(type: string): void {
    if ( type === 'dartOne' ) {
      this.score[this.playerId][this.dartOne.point] =
          this.score[this.playerId][this.dartOne.point] - this.dartOne.number;
      this.dartOne = { number : null , point : null};
    } else if ( type === 'dartSecond' ) {
      this.score[this.playerId][this.dartSecond.point] =
          this.score[this.playerId][this.dartSecond.point] - this.dartSecond.number;
      this.dartSecond = { number : null , point : null};
    } else if ( type === 'dartThird' ) {
      this.score[this.playerId][this.dartThird.point] =
          this.score[this.playerId][this.dartThird.point] - this.dartThird.number;
      this.dartThird = { number : null , point : null};
    }
  }

  newArray(number: number): Array<number> {
    return new Array(number);
  }

  saveLocalStorage(): void {
    const total = this.scoreTotal.slice();
    const score = [];
    this.score.forEach(sc => {
      score.push(sc.slice());
    });
    this.cache.push({score : score, total : total });
  }
}
